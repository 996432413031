<template>
    <div class="live-center">
        <div class="live-center-top">
            <div @click="tabsLive(1)" :class="{activat: isActivat == 1}">{{$t('userLive.title_01')}}</div>
            <div @click="tabsLive(2)" :class="{activat: isActivat == 2}">{{$t('userLive.title_02')}}</div>
            <div @click="tabsLive(3)" :class="{activat: isActivat == 3}">{{$t('userLive.title_03')}}</div>
        </div>
        <div class="user-caller-list">
            <div class="user-caller-item" v-for="(item,index) in userLiveList" :key="index">
                <img @click="goUserForm(item.userId)" :src="$isApi.http + item.header" alt="">
                <div>
                    <div class="user-caller-content">
                        <span @click="goUserForm(item.userId)">{{item.nickName}}</span>
                        <div v-if="item.sex == 'women'" style="background:#FE9ED4">
                            <i class="iconfont icon-xingbienv1"></i>
                            <span>{{item.age}}</span>
                        </div>
                        <div v-else style="background:#6798FF">
                            <i class="iconfont icon-xingbienan1"></i>
                            <span>{{item.age}}</span>
                        </div>
                    </div>
                    <div class="live-center-zh" v-if="item.marriageStatus || item.weight || item.height || item.education || item.occupation || item.income">
                        <span v-if="item.marriageStatus">{{item.marriageStatus}}</span>
                        <span v-if="item.weight">{{item.weight}}kg</span>
                        <span v-if="item.height">{{item.height}}cm</span>
                        <span v-if="item.education">{{item.education}}</span>
                        <span v-if="item.occupation">{{item.occupation}}</span>
                        <span v-if="item.income">{{item.income}}</span>
                    </div>
                    <div class="user-caller-qian">
                        <span>{{$t('userCaller.text')}}：{{item.signature}}</span>
                    </div>
                </div>
            </div>
            <el-pagination
                class="page-size-number"
                background
                @current-change="currentChange"
                :page-size="16"
                :current-page="pageNo"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            isActivat: 1,
            userLiveList: [],
            total: 1,
            pageNo: 1,
            url: this.$isApi.showMyLike
        }
    },
    created(){
        this.liveLi(this.url,1)
    },
    methods: {
        // 获取列表(默认我喜欢的)
        liveLi(url,pageNo){
            this.$isAxios.axiosGet(url,{pageNo:pageNo,pageSize:16},(res) => {
                if(res.data.message == 'success'){
                    this.userLiveList = res.data.result.users
                    this.total = res.data.result.total
                }
            })
        },
        // 切换选项卡
        tabsLive(e){
            this.isActivat = e
            if(e == 1){
                // 我喜欢的
                this.url = this.$isApi.showMyLike
                this.$nextTick(()=>{
                    this.liveLi(this.url,1)
                })
            }else if(e == 2){
                // 喜欢我的
                this.url = this.$isApi.showLikeMe
                this.$nextTick(()=>{
                    this.liveLi(this.url,1)
                })
            }else{
                // 相互喜欢的
                this.url = this.$isApi.findFriend
                this.$nextTick(()=>{
                    this.liveLi(this.url,1)
                })
            }
        },
        // 分页数据
        currentChange(e){
            this.liveLi(this.url,e)
        },
        // 跳转用户主页
        goUserForm(id){
            sessionStorage['userId'] = id
            this.$isAxios.axiosPost(this.$isApi.addVisitor,{userId:id,visitorId:this.sendForm.userId},(fang)=>{
                if(fang.data.result){
                    this.$router.push({
                        path: "/page/userXQ",
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.live-center{
    background: #fff;
    padding: 20px;
}
.live-center-top{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.live-center-top>div{
    margin-right: 10px;
    padding: 8px 16px;
    border-radius: 4px;
    background: #eee;
    font-size: 14px;
    cursor: pointer;
}
.activat{
    background-image:-webkit-linear-gradient(135deg,#e400ff, #ffcc00) !important;
    color: #fff;
}
.user-caller-list{
    height: max-content;
    margin-top: 18px;
}
.user-caller-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #eee
}
.user-caller-item:last-child{
    border: none
}
.user-caller-item>img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background: #000;
    object-fit: cover;
    flex-grow: 0;
    cursor: pointer;
}
.user-caller-item>div{
    width: calc( 100% - 80px );
    height: max-content;
}
.user-caller-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: max-content;
    margin-bottom: 4px
}
.live-center-zh{
    font-size: 12px;
    color: #999;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 12px;
    line-height: 12px;
    margin-bottom: 2px
}
.live-center-zh>span{
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #999
}
.live-center-zh>span:last-child{
    border: none
}
.user-caller-content>span{
    font-size: 16px;
    color: #333;
    font-weight: bold;
    cursor: pointer;
}
.user-caller-content>span:hover{
    text-decoration: underline
}
.user-caller-content>div:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 18px;
    padding: 0 6px;
    border-radius: 9px;
    overflow: hidden;
    margin-left: 10px
}
.user-caller-content>div:nth-child(2)>i{
    display: block;
    font-size: 14px;
    color: #fff;
    margin-top: 2px;
    font-weight: bold;
}
.user-caller-content>div:nth-child(2)>span{
    display: block;
    font-size: 12px;
    color: #fff;
}
.user-caller-content>div:nth-child(3){
    flex: 1;
    text-align: right;
    font-size: 12px;
    color: #999;
}
.user-caller-qian{
    font-size: 13px;
    color: #666;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>